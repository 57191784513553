import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { TopNavBar } from "../../components/navbars/topNavbar/topNavbar";
import { BlogUseStyles } from "../createBlog/style";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { AlertProps, Message, NetWorkCallMethods } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { Routes } from "../../router/routes";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { imageUploads } from "../blogs/utils";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import Upload from "../createBlog/uploadImage";

const CategoryImage = () => {
  const classes = BlogUseStyles();
  const alert = React.useContext(AlertContext);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const history = useHistory();
  const state = useLocation();
  const [formValues, setFormValues] = useState({
    category_name: "",
    category_url: "",
    meta_title: "",
    meta_desc: "",
    banner_link: "",
    banner_img: "",
    banner_img: "",
    alt_tag: "",
    error: { file_image: "" },
    isLoading: false,
    status: false,
  });
  const [options, setOptions] = React.useState([]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleStatusChange = (event) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      status: event.target.checked,
    }));
  };

  const handlecategoryFileChange = async (e) => {
    const file = e?.target?.files[0];

    if (!file) {
      return; // Exit function if no file is selected
    }

    const fileSizeInBytes = file.size;
    const fileSizeInMegabytes = fileSizeInBytes / (1024 * 1024);

    if (fileSizeInMegabytes < 4) {
      try {
        const updateTheImage = await imageUploads(file);

        setFormValues((prevValues) => ({
          ...prevValues,
          banner_img: updateTheImage?.response, // Update banner_file_url instead of file_url
          error: { ...prevValues.error, banner_img: "" }, // Update error message key
        }));

        // Additional logic if needed after successful upload
      } catch (error) {
        console.log("Error uploading banner image:", error);
        // Handle error if upload fails
      }
    } else {
      // File size exceeds limit, update error message
      setFormValues((prevValues) => ({
        ...prevValues,
        error: {
          ...prevValues.error,
          banner_img: "Upload Banner Image below 3 MB",
        }, // Update error message key
      }));
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Validation checks
    const errors = {};
    let isValid = true;

    if (!formValues.category_name.trim()) {
      errors.title = "Title is required";
      isValid = false;
    }

    if (!isValid) {
      setFormValues((prevValues) => ({
        ...prevValues,
        error: errors,
      }));
      return;
    }

    // If form is valid, proceed with form submission
    if (state?.state?.id) {
      updatecategory();
    } else {
      addcategory();
    }
  };

  const addcategory = () => {
    let payload = {
      category_name: formValues.category_name,
      category_url: formValues.category_url,
      meta_title: formValues.meta_title,
      meta_desc: formValues.meta_desc,
      banner_img: formValues.banner_img === "" ? null : formValues.banner_img,
      banner_link: formValues.banner_link,
      alt_tag: formValues.alt_tag,
      is_active: formValues.status,
    };
    NetworkCall(
      `${config.api_feature}api/v1/blog_categories/create_category`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setIsButtonDisabled(true);

        window.location.href = Routes.addCategory;
      })
      .catch((err) => {
        console.log(err);

        if (err?.response?.status === 409) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: err?.response?.data?.message,
          });
          return;
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: Message.BlogErr,
          });
        }
      });
  };

  const updatecategory = () => {
    let payload = {
      id: state?.state?.id,
      category_name: formValues.category_name,
      category_url: formValues.category_url,
      meta_title: formValues.meta_title,
      meta_desc: formValues.meta_desc,
      banner_img: formValues.banner_img === "" ? null : formValues.banner_img,
      banner_link: formValues.banner_link,
      alt_tag: formValues.alt_tag,
      is_active: formValues.status,
    };
    NetworkCall(
      `${config.api_feature}api/v1/blog_categories/edit_category`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setIsButtonDisabled(true);

        window.location.href = Routes.addCategory;
      })
      .catch((err) => {
        console.log(err);

        if (err?.response?.status === 409) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: err?.response?.data?.message,
          });
          return;
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: Message.BlogErr,
          });
        }
      });
  };

  useEffect(() => {
    if (state?.state?.id) {
      editcategory();
    }
  }, []);

  const editcategory = () => {
    let payload = {
      id: state?.state?.id,
      is_active: true,
    };
    NetworkCall(
      `${config.api_feature}api/v1/blog_categories/edit_category`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setFormValues((prevValues) => ({
          ...prevValues,
          category_name: res?.data?.updated_category?.category_name,
          category_url: res?.data?.updated_category?.category_url,
          meta_title: res?.data?.updated_category?.meta_title,
          meta_desc: res?.data?.updated_category?.meta_desc,
          banner_img: res?.data?.updated_category?.banner_img,
          banner_link: res?.data?.updated_category?.banner_link,
          alt_tag: res?.data?.updated_category?.alt_tag,
          status: res?.data?.updated_category?.is_active,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <TopNavBar
        title="Provision to Add and Edit category"
        buttonLabel={false}
        disables={isButtonDisabled}
      />
      <form onSubmit={handleFormSubmit} style={{ height: "90vh" }}>
        <Grid p={2} container spacing={2} alignItems="stretch">
          <Grid textAlign={"right"} item xs={12}>
            <Box>
              <button className={classes.button} type="submit">
                {state?.state?.id ? "Update" : "Submit"}
              </button>
            </Box>
          </Grid>
          {/* Left grid for Title, Category, and Hashtags */}
          <Grid item lg={7} md={7} sm={7} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    label="category Name"
                    name="category_name"
                    value={formValues.category_name}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    label="category url"
                    name="category_url"
                    value={formValues.category_url}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    label="category Meta Title"
                    name="meta_title"
                    value={formValues.meta_title}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <TextField
                    label="category meta Description"
                    name="meta_desc"
                    value={formValues.meta_desc}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <TextField
                    label="category rightSide Img Alt"
                    name="alt_tag"
                    value={formValues.alt_tag}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <TextField
                    label="category rightSide Img Url"
                    name="banner_link"
                    value={formValues.banner_link}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formValues?.status}
                        onChange={handleStatusChange}
                      />
                    }
                    label={"Active"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Right grid for Upload image */}
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <Box
              display="grid"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              {/* Banner Image Section */}
              {!formValues.banner_img ? (
                <Button
                  component="label"
                  role={undefined}
                  variant="outlined"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload category Image
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handlecategoryFileChange}
                  />
                </Button>
              ) : (
                <>
                  <img
                    src={formValues.banner_img}
                    alt="banner"
                    style={{
                      width: "100%",
                      height: "260px",
                      paddingBottom: "10px",
                    }}
                  />
                  <Box display="flex" justifyContent="center">
                    <Button
                      onClick={() =>
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          banner_img: "",
                        }))
                      }
                      style={{
                        backgroundColor: "transparent",
                        color: "red",
                        padding: "2px 4px",
                        fontSize: "13px",
                        fontWeight: "bold",
                        textTransform: "none",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                      }}
                    >
                      Remove category Image
                    </Button>
                  </Box>
                </>
              )}
              <Box height="20px" />
            </Box>
          </Grid>

          {/* Error message */}
          {formValues.error.file_image && (
            <Typography color="error">{formValues.error.file_image}</Typography>
          )}

          {/* Submit button */}
        </Grid>
      </form>
    </div>
  );
};

export default CategoryImage;
