import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { TopNavBar } from "../../components/navbars/topNavbar/topNavbar";
import { BlogUseStyles } from "./style";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { AlertProps, Message, NetWorkCallMethods } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { Routes } from "../../router/routes";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { imageUploads } from "../blogs/utils";
import Upload from "./uploadImage";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

const CreateBlogPage = () => {
  const classes = BlogUseStyles();
  const alert = React.useContext(AlertContext);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const history = useHistory();
  const state = useLocation();
  const [formValues, setFormValues] = useState({
    title: "",
    meta_title: "",
    schema_code: "",
    category: null,
    author: null,
    hashtags: [],
    readMinutes: "",
    meta_data: { meta_description: "", background_color: "", og_image: "" },
    sections: [{ title: "", description: EditorState.createEmpty() }],
    image_url: "",
    error: { file_image: "" },
    isLoading: false,
    status: false,
  });
  const [options, setOptions] = React.useState([]);
  const [authorOptions, setAuthorOptions] = React.useState([]);

  // Function to get HTML content from editor state
  const getHtmlContent = (content) => {
    const contentState = content.getCurrentContent();
    const html = stateToHTML(contentState);
    return html;
  };

  // Function to set HTML content to editor state
  // const setHtmlContent = (html) => {
  //   const contentState = stateFromHTML(html);
  //   const newEditorState = EditorState.createWithContent(contentState);
  //   setEditorState(newEditorState);
  // };

  const handleStatusChange = (event) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      status: event.target.checked,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (
      name === "meta_description" ||
      name === "background_color" ||
      name === "og_image"
    ) {
      setFormValues((prevState) => ({
        ...prevState,
        meta_data: {
          ...prevState.meta_data,
          [name]: value,
        },
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSectionTitleChange = (e, index) => {
    const { value } = e.target;
    const updatedSections = [...formValues.sections];
    updatedSections[index].title = value;
    setFormValues((prevValues) => ({
      ...prevValues,
      sections: updatedSections,
    }));
  };

  const handleEditorStateChange = (editorState, index) => {
    const updatedSections = [...formValues.sections];
    updatedSections[index].description = editorState;
    setFormValues((prevValues) => ({
      ...prevValues,
      sections: updatedSections,
    }));
  };

  const handleBannerFileChange = async (e) => {
    const file = e?.target?.files[0];

    if (!file) {
      return; // Exit function if no file is selected
    }

    const fileSizeInBytes = file.size;
    const fileSizeInMegabytes = fileSizeInBytes / (1024 * 1024);

    if (fileSizeInMegabytes < 4) {
      try {
        const updateTheImage = await imageUploads(file);

        setFormValues((prevValues) => ({
          ...prevValues,
          image_url: updateTheImage?.response, // Update banner_file_url instead of file_url
          error: { ...prevValues.error, image_url: "" }, // Update error message key
        }));

        // Additional logic if needed after successful upload
      } catch (error) {
        console.log("Error uploading banner image:", error);
        // Handle error if upload fails
      }
    } else {
      // File size exceeds limit, update error message
      setFormValues((prevValues) => ({
        ...prevValues,
        error: {
          ...prevValues.error,
          image_url: "Upload Banner Image below 3 MB",
        }, // Update error message key
      }));
    }
  };
  const handleOGFileChange = async (e) => {
    const file = e?.target?.files[0];

    if (!file) {
      return; // Exit function if no file is selected
    }

    const fileSizeInBytes = file.size;
    const fileSizeInMegabytes = fileSizeInBytes / (1024 * 1024);

    if (fileSizeInMegabytes < 4) {
      try {
        // Handle file upload asynchronously
        const updateTheImage = await imageUploads(file);

        // Update formValues and reset error if successful
        setFormValues((prevValues) => ({
          ...prevValues,
          meta_data: {
            ...prevValues.meta_data,
            og_image: updateTheImage?.response, // Set the image's URL inside meta_data.og_image
          },
          error: { ...prevValues.error, og_image: "" },
        }));

        // Additional logic if needed after successful upload
      } catch (error) {
        console.log("Error uploading OG image:", error);
        // Handle error if upload fails
      }
    } else {
      // File size exceeds limit, update error message
      setFormValues((prevValues) => ({
        ...prevValues,
        error: { ...prevValues.error, og_image: "Upload OG Image below 3 MB" }, // Update error message key
      }));
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Validation checks
    const errors = {};
    let isValid = true;

    if (!formValues.title.trim()) {
      errors.title = "Title is required";
      isValid = false;
    }

    if (
      !formValues.category ||
      !formValues.category.label.trim() ||
      !formValues.category.value.trim()
    ) {
      errors.category = "Category is required";
      isValid = false;
    }

    if (!formValues.hashtags.length) {
      errors.hashtags = "Hashtags are required";
      isValid = false;
    } else {
      for (let i = 0; i < formValues.hashtags.length; i++) {
        if (
          typeof formValues.hashtags[i] === "string" &&
          !formValues.hashtags[i].trim()
        ) {
          errors.hashtags = "Hashtags should not contain empty strings";
          isValid = false;
          break;
        }
      }
    }

    if (!formValues.title_url.trim()) {
      errors.title_url = "Title URL is required";
      isValid = false;
    } else if (!/^([a-z0-9]+-)*[a-z0-9]+$/.test(formValues.title_url)) {
      errors.title_url =
        "Title URL should be in kebab-case (lowercase letters, numbers, and hyphens only)";
      isValid = false;
    }

    // Check sections for empty titles or descriptions
    formValues.sections.forEach((section, index) => {
      if (!section.title.trim()) {
        errors[`sectionTitle${index}`] = `Section Title ${
          index + 1
        } is required`;
        isValid = false;
      }
      if (!section.description.getCurrentContent().getPlainText().trim()) {
        errors[`sectionDescription${index}`] = `Section Description ${
          index + 1
        } is required`;
        isValid = false;
      }
    });

    if (!isValid) {
      setFormValues((prevValues) => ({
        ...prevValues,
        error: errors,
      }));
      return;
    }

    // If form is valid, proceed with form submission
    if (state?.state?.id) {
      updateBlog();
    } else {
      addBlog();
    }
  };

  const addSection = (index) => {
    const newSection = { title: "", description: EditorState.createEmpty() };
    const updatedSections = [
      ...formValues.sections.slice(0, index + 1), // Copy sections up to the insert point
      newSection, // Insert the new section
      ...formValues.sections.slice(index + 1), // Copy remaining sections
    ];
    setFormValues((prevValues) => ({
      ...prevValues,
      sections: updatedSections,
    }));
  };
  const deleteSection = (index) => {
    const updatedSections = formValues.sections.filter((_, i) => i !== index);
    setFormValues((prevValues) => ({
      ...prevValues,
      sections: updatedSections,
    }));
  };

  const addBlog = () => {
    let sectionsPayload = formValues.sections.map((section) => ({
      section_title: section.title,
      section_content: getHtmlContent(section.description),
    }));
    let payload = {
      category_id: formValues.category?.value,
      author_id: formValues.author?.value,
      title: formValues.title,
      title_url: formValues.title_url,
      meta_title: formValues.meta_title,
      schema_code: formValues.schema_code,
      image_url: formValues.image_url === "" ? null : formValues.image_url,
      read_minutes: formValues.readMinutes,
      sections: sectionsPayload,
      hashtags: formValues.hashtags,
      is_draft: formValues.status,
      meta_data: {
        meta_description: formValues.meta_data.meta_description,
        background_color: formValues.meta_data.background_color,
        og_image: formValues.meta_data.og_image,
      },
    };
    NetworkCall(
      `${config.api_feature}api/v1/blog/create_blog`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setIsButtonDisabled(true);
        window.location.href = Routes.blogs;
      })
      .catch((err) => {
        console.log(err);

        if (err?.response?.status === 409) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: err?.response?.data?.message,
          });
          return;
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: Message.BlogErr,
          });
        }
      });
  };

  const updateBlog = () => {
    let sectionsPayload = formValues.sections.map((section) => ({
      section_number: section?.sectionNumber,
      section_title: section.title,
      section_content: getHtmlContent(section.description),
    }));

    let payload = {
      blog_id: state?.state?.id,
      category_id: formValues.category?.value,
      author_id: formValues.author?.value,
      title: formValues.title,
      title_url: formValues.title_url,
      meta_title: formValues.meta_title,
      schema_code: formValues.schema_code,
      image_url: formValues.image_url === "" ? null : formValues.image_url,
      read_minutes: formValues.readMinutes,
      sections: sectionsPayload,
      hashtags: formValues.hashtags,
      is_draft: formValues.status,
      meta_data: {
        meta_description: formValues.meta_data.meta_description,
        background_color: formValues.meta_data.background_color,
        og_image: formValues.meta_data.og_image,
      },
    };
    console.log(payload);
    NetworkCall(
      `${config.api_feature}api/v1/blog/edit_blog`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setIsButtonDisabled(true);

        window.location.href = Routes.blogs;
      })
      .catch((err) => {
        console.log(err);

        if (err?.response?.status === 409) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: err?.response?.data?.message,
          });
          return;
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: Message.BlogErr,
          });
        }
      });
  };

  const getCategoryList = () => {
    NetworkCall(
      `${config.api_feature}api/v1/blog/get_categories`,
      NetWorkCallMethods.get,
      null,
      null,
      true,
      false
    )
      .then((res) => {
        let response = res?.data?.categories;
        setOptions(
          response?.map((item) => {
            return {
              label: item?.category_name,
              value: item?.id,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAuthorList = () => {
    NetworkCall(
      `${config.api_feature}api/v1/authors/get_authors`,
      NetWorkCallMethods.get,
      null,
      null,
      true,
      false
    )
      .then((res) => {
        let response = res?.data?.authors;
        setAuthorOptions(
          response?.map((item) => {
            return {
              label: item?.name,
              value: item?.id,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (state?.state?.id) {
      editBlog();
    }
    getCategoryList();
    getAuthorList();
  }, []);

  const editBlog = () => {
    let payload = {
      blog_id: state?.state?.id,
      author_id: "",
    };
    NetworkCall(
      `${config.api_feature}api/v1/blog/get_blog_details`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setFormValues((prevValues) => ({
          ...prevValues,
          title: res?.data?.blog?.title,
          title_url: res?.data?.blog?.title_url,
          meta_title: res?.data?.blog?.meta_title,
          schema_code: res?.data?.blog?.schema_code,
          // category: res?.data?.blog?.category?.id,
          category: {
            label: res?.data?.blog?.category?.category_name,
            value: res?.data?.blog?.category?.id,
          },
          author: {
            label: res?.data?.blog?.author?.name,
            value: res?.data?.blog?.author?.id,
          },
          meta_data: {
            meta_description: res?.data?.blog?.meta_data?.meta_description,
            background_color: res?.data?.blog?.meta_data?.background_color,
            og_image: res?.data?.blog?.meta_data?.og_image,
          },
          readMinutes: res?.data?.blog?.read_minutes,
          image_url: res?.data?.blog?.image_url,
          hashtags: res?.data?.blog?.hashtags.map((item) => item.hashtag),
          status: res?.data?.blog?.is_draft,
          sections: (res?.data?.blog?.sections || [])
            .map((section) => {
              const blocksFromHTML = convertFromHTML(section?.section_content);
              const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
              );
              return {
                title: section?.section_title,
                description: EditorState.createWithContent(state),
                sectionNumber: section?.section_number || Infinity, // Default to 0 if section_number is missing
              };
            })
            .sort((a, b) => a.sectionNumber - b.sectionNumber), // Sort by sectionNumber
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const customEditorStyles = {
    // Custom styles for the editor toolbar
    editorContainer: {
      height: "300px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      overflowY: "auto", // Enable vertical scrolling for content exceeding 300px height
    },
    // Custom styles for the editor toolbar
    toolbar: {
      position: "sticky",
      top: 0,
      zIndex: 1000, // Adjust the z-index as needed
      backgroundColor: "#fff", // Customize the background color if needed
    },
  };

  return (
    <div>
      <TopNavBar
        title="Provision to Add and Edit Blogs"
        buttonLabel={false}
        disables={isButtonDisabled}
      />
      <form onSubmit={handleFormSubmit} style={{ height: "90vh" }}>
        <Grid p={2} container spacing={2} alignItems="stretch">
          <Grid textAlign={"right"} item xs={12}>
            <Box>
              <button className={classes.button} type="submit">
                {state?.state?.id ? "Update" : "Submit"}
              </button>
            </Box>
          </Grid>
          {/* Left grid for Title, Category, and Hashtags */}
          <Grid item lg={7} md={7} sm={7} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    label="Title"
                    name="title"
                    value={formValues.title}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    label={!state?.state?.id ? "Title URL" : null}
                    name="title_url"
                    value={formValues.title_url}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={!!state?.state?.id}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    label="Meta Title"
                    name="meta_title"
                    value={formValues.meta_title}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    label="Meta Description"
                    name="meta_description"
                    value={formValues.meta_data.meta_description}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    label="Background Color"
                    name="background_color"
                    value={formValues.meta_data.background_color}
                    onChange={handleInputChange}
                    fullWidth
                    inputProps={{ pattern: "^#(?:[0-9a-fA-F]{3}){1,2}$" }}
                    helperText="Please enter a valid hexadecimal color value (e.g., #RRGGBB or #RGB)"
                  />
                </Box>
              </Grid>

              {/* Other fields (Category, Hashtags) */}
              <Grid item xs={6}>
                <Box>
                  <Autocomplete
                    id="category"
                    options={options ?? []}
                    getOptionLabel={(option) => option?.label}
                    autoSelect
                    renderInput={(params) => (
                      <TextField {...params} label="Category" />
                    )}
                    value={formValues?.category} // Use optional chaining
                    onChange={(event, newValue) => {
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        category: newValue,
                      }));
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <TextField
                    label="Read Minutes"
                    name="readMinutes"
                    value={formValues.readMinutes}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Autocomplete
                    id="authorList"
                    options={authorOptions ?? []}
                    getOptionLabel={(option) => option?.label}
                    autoSelect
                    renderInput={(params) => (
                      <TextField {...params} label="Author" />
                    )}
                    value={formValues?.author} // Use optional chaining
                    onChange={(event, newValue) => {
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        author: newValue,
                      }));
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <MuiChipsInput
                    label="Hashtags"
                    value={formValues.hashtags}
                    onChange={(e) => {
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        hashtags: e,
                      }));
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    multiline
                    rows={4}
                    variant="outlined"
                    label="schema code"
                    name="schema_code"
                    value={formValues.schema_code}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Right grid for Upload image */}
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <Box
              display="grid"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              {/* Banner Image Section */}
              {!formValues.image_url ? (
                <Button
                  component="label"
                  role={undefined}
                  variant="outlined"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Banner Image
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleBannerFileChange}
                  />
                </Button>
              ) : (
                <>
                  <img
                    src={formValues.image_url}
                    alt="banner"
                    style={{
                      width: "100%",
                      height: "260px",
                      paddingBottom: "10px",
                    }}
                  />
                  <Box display="flex" justifyContent="center">
                    <Button
                      onClick={() =>
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          image_url: "",
                        }))
                      }
                      style={{
                        backgroundColor: "transparent",
                        color: "red",
                        padding: "2px 4px",
                        fontSize: "13px",
                        fontWeight: "bold",
                        textTransform: "none",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                      }}
                    >
                      Remove Banner Image
                    </Button>
                  </Box>
                </>
              )}
              <Box height="20px" />

              {/* OG Image Section */}
              {!formValues?.meta_data?.og_image ? (
                <Box
                  display="grid"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload OG Image
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleOGFileChange}
                    />
                  </Button>
                </Box>
              ) : (
                <>
                  <img
                    src={formValues?.meta_data?.og_image}
                    alt="og"
                    style={{
                      width: "100%",
                      height: "260px",
                      paddingBottom: "10px",
                    }}
                  />
                  <Box display="flex" justifyContent="center">
                    <Button
                      onClick={() =>
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          meta_data: {
                            ...prevValues.meta_data,
                            og_image: "",
                          },
                        }))
                      }
                      style={{
                        backgroundColor: "transparent",
                        color: "red",
                        padding: "2px 4px",
                        fontSize: "13px",
                        fontWeight: "bold",
                        textTransform: "none",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                      }}
                    >
                      Remove OG Image
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Grid>

          {/* Section Title and Content */}
          {formValues.sections.map((section, index) => (
            <>
              <Grid key={index} item xs={12}>
                <Box mb={1}>
                  <TextField
                    label={`Section Title ${index + 1}`}
                    name={`sectionTitle${index}`}
                    value={section.title}
                    onChange={(e) => handleSectionTitleChange(e, index)}
                    fullWidth
                  />
                </Box>
                <Box
                  style={{
                    height: "300px",
                    overflow: "scroll",
                    backgroundColor: "white",
                    padding: "12px",
                  }}
                >
                  <Editor
                    toolbarStyle={customEditorStyles.toolbar}
                    editorState={section.description}
                    onEditorStateChange={(editorState) =>
                      handleEditorStateChange(editorState, index)
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box>
                  <button
                    className={classes.button}
                    type="button"
                    onClick={() => addSection(index)}
                    style={{ marginTop: "16px" }}
                  >
                    Add 
                  </button>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box>
                  <button
                    className={classes.button}
                    type="button"
                    onClick={() => deleteSection(index)}
                    style={{ marginTop: "16px" }}
                  >
                    Delete 
                  </button>
                </Box>
              </Grid>
            </>
          ))}

          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={formValues?.status}
                    onChange={handleStatusChange}
                  />
                }
                label={"Draft"}
              />
            </Box>
          </Grid>

          {/* Add Section button */}
          {/* Error message */}
          {formValues.error.file_image && (
            <Typography color="error">{formValues.error.file_image}</Typography>
          )}

          {/* Submit button */}
        </Grid>
      </form>
    </div>
  );
};

export default CreateBlogPage;
