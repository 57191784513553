import React from "react";
import { withNavBars } from "../../HOCs";
import AddAuthors from "./addAuthor";
class addAuthorsParent extends React.Component {
  render() {
    return <AddAuthors />;
  }
}

export default withNavBars(addAuthorsParent);
