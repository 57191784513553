import React from "react";
import { withNavBars } from "../../HOCs";
import AuthorList from "./authorList";
class authorListParent extends React.Component {
  render() {
    return <AuthorList />;
  }
}

export default withNavBars(authorListParent);
