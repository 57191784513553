import React from "react";
import AddCategorys from "./addCategory";
import { withNavBars } from "../../HOCs";

class addCategorysParent extends React.Component {
  render() {
    return <AddCategorys />;
  }
}
export default withNavBars(addCategorysParent);
