import React from "react";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box } from "@mui/system";
import { Upload } from "../../components/upload/fileupload";
import { EventUseStyles } from "./style";

export const CreateUploadPopup = (props) => {
  const {
    options,
    value,
    updateState,
    PopupSubmitHandler,
    submitButton,
    isLoading,
    fileOnChange,
    fileOnChangePoster,
    onImageDelete,
    changePosterDelete,
    tableData,
  } = props;
  const classes = EventUseStyles();

  const myArray = value?.file_image?.response?.split(".");
  const isPrevPosition = tableData?.find(
    (val) => val.priority === Number(value?.position)
  );
  const indexOfFormat = myArray?.length - 1;
  const format = myArray?.[indexOfFormat];
  const handleUpload = (e) => {
    fileOnChangePoster(e);
  };
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.frstRow}>
          <Autocomplete
            id="partner_type"
            options={options ?? []}
            getOptionLabel={(option) => option.label}
            autoSelect
            renderInput={(params) => (
              <TextField {...params} label="Assets Type" />
            )}
            defaultValue={{ label: "", value: "" }}
            value={options?.find(
              (val) =>
                val?.value === value?.partner_type || value?.partner_type?.value
            )}
            onChange={(event, newValue) => {
              updateState("partner_type", newValue);
            }}
          />
        </Box>

        <Box className={classes.frstRow}>
          <Typography
            style={{ fontSize: "12px", color: "#98A0AC", paddingLeft: "14px" }}
          >
            Alter Text
          </Typography>
          <TextField
            value={value?.alter_text}
            onChange={(event) => {
              updateState("alter_text", event.target.value);
            }}
          />
        </Box>

        <Box className={classes.frstRow}>
          <Typography
            style={{ fontSize: "12px", color: "#98A0AC", paddingLeft: "14px" }}
          >
            Position
          </Typography>
          <TextField
            value={value?.position}
            type="number"
            onChange={(event) => {
              updateState("position", event.target.value);
            }}
          />
          {isPrevPosition ? (
            <Typography
              style={{
                fontSize: "11px",
                paddingLeft: "14px",
                color: "#f45e2d",
                marginTop: "-9px",
              }}
            >
              {"Position Already Used"}
            </Typography>
          ) : (
            ""
          )}
        </Box>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.finalRow}>
          {value?.file_image_url || value?.file_image ? (
            format === "mp4" ? (
              <video
                height="100%"
                width="100%"
                className={classes.eventvideo}
                autoPlay="true"
                // loop
              >
                <source
                  src={
                    value?.file_image?.response
                      ? value?.file_image?.response
                      : "data:image;base64," + value?.file_image_url
                  }
                  type="video/mp4"
                />
              </video>
            ) : (
              <img
                style={{ height: 100, width: 380 }}
                src={
                  value?.file_image?.response
                    ? value?.file_image?.response
                    : "data:image;base64," + value?.file_image_url
                }
                alt="upload_image"
              />
            )
          ) : (
            <Upload
              fileOnChange={fileOnChange}
              single
              data={value}
              isLoading={isLoading}
            />
          )}

          {(value?.file_image_url || value?.file_image) && (
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
              onClick={() => onImageDelete()}
              // onClick={() => fileOnChange()}
            >
              {" "}
              <DeleteForeverIcon style={{ color: "#FF300F" }} />
            </div>
          )}
        </Box>
      </Grid>
      {value.error.file_image && (
        <Typography className={classes.errorMsg}>
          {value.error.file_image}
        </Typography>
      )}

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ padding: "8px 16px 14px 16px" }}
      >
        <Typography
          className={classes.btn}
          onClick={(e) => PopupSubmitHandler(e)}
        >
          {submitButton}
        </Typography>
      </Grid>
    </Grid>
  );
};
