export const Routes = {
  createSubscription: "/create_subscription",
  customers: "/customers",
  login: "/",
  dashboard: "/dashboard",
  customersdetails: "/customersdetails",
  subscription: "/subscription",
  subscriptiondetails: "/subscriptiondetails",
  plan: "/plan",
  plandetails: "/plandetails",
  addons: "/addons",
  charges: "/charges",
  featuresGroup: "/featuresGroup",
  changePassword: "/changepassword",
  settings: "/settings",
  coupons: "/coupons",
  configuration: "/configuration",
  features: "/features",
  partnership: "/partnership",
  pricing: "/pricing",
  everyOneBecomePartner: "/everyOneBecomePartner",
  demo: "/demo",
  webinarConfig: "/webinarConfig",
  event: "/event",
  event_members: "/event-members",
  landingPageUpload: "/landingPageUpload",
  aboutUsUpload: "/aboutUsUpload",
  careersUpload: "/careersUpload",
  blogs: "/blogs",
  createBlog: "/createBlog",
  authorList: "/authorList",
  addAuthors: "/addAuthors",
  addCategory: "/addCategory",
  categoryImage: "/categoryImage",
};
