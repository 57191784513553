import React from "react";
import { withNavBars } from "../../HOCs";
import CategoryImage from "./addCategories";

class categoryImageParent extends React.Component {
  render() {
    return <CategoryImage />;
  }
}

export default withNavBars(categoryImageParent);
